import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Lift accessed mountain biking on long natural trails. Magnificent mountain
views. Italian food, coffee, and wine combined with a relaxed and pleasant
atmosphere. If these are the traits of your perfect mountain biking holiday
you should go to the small village of La Thuile.`}</p>
    <br />
    <p>{`La Thuile is located at the very end of the Aosta Valley in the northwest
corner of Italy right by the Mont Blanc tunnel with Chamonix on the other
side.`}</p>
    <br />
    <p>{`The local bikers have made the small village famous among enduro riders
by exclusively focusing on natural trails in the magnificent terrain
surrounding it. This focus has resulted in La Thuile being the host of the
Enduro World Series (EWS) in 2014, 2016 and again this year in 2018.`}</p>
    <TrailguidePlugin content="lat=45.6965&lng=6.9375&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=14,45.6965,6.9375"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`The average bike park with machine built trails can often get boring after
a day or two. This is not the case with the trails of La Thuile where
naturally adapted and handmade is the name of the game. Two efficient chair
lifts take you from 1440 meters in the village to 2340 meters well above the
tree line while enjoying the views of the trails below, the Testa del Rutor
with its glacier, and of course the mighty Mont Blanc.`}</p>
    <br />
    <p>{`From the top of the second chair lift you get access to a great network of
trails ranging from blue flow trails to long red high alpine trails to really
steep and black world class enduro trails with roots, rocks, and tight hairpin
turns. The level of riding in La Thuile is mostly centered around the red and
black trails, but it never gets too difficult. Just slow down or walk a few
meters to avoid the worst obstacles if you are not one of the Enduro World
Series contenders.`}</p>
    <br />
    <p>{`The common denominator of all trails in La Thuile is the length, vertical
drop, and the handmade quality all the way down to the village. Even though
La Thuile has hosted the Enduro World Series, the number of people riding
the trails seems to be low. You mostly feel you have all these great trails
to yourself. Only occationally you will meet other bikers down at one of
the nice restaurants or bars.`}</p>
    <br />
    <br />
    <h4>{`La Tour`}</h4>
    <p>{`One of the absolute classics of the La Thuile bike park. A trail that has
often been used for the Enduro World Series as well as the Italian
Superenduro series.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1144"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/lathuile_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Chalet Express`}</h4>
    <p>{`One of the fast enduro trails above the tree line in La Thuile .
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1154"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/lathuile_5.jpg" mdxType="Image" />
    <br />
    <h4>{`France`}</h4>
    <p>{`You can even cross the border to France.`}</p>
    <Image src="destinations/lathuile_1.jpg" mdxType="Image" />
    <br />
    <h4>{`K2`}</h4>
    <p>{`The 4 kilometer long K2 trail with great views of the Rutor.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1153"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/lathuile_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Garin`}</h4>
    <p>{`The trails on each side of the mountain are not so steep. This is not the
case for the trails in the middle.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1158"
      }}>{`here`}</a></p>
    <Image src="destinations/lathuile_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Muret`}</h4>
    <p>{`One of the many trails to be found in the deep and steep pine forest down
to the village.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1155"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/lathuile_4.jpg" mdxType="Image" />
    <p>{`But you also find some easier parts.`}</p>
    <Image src="destinations/lathuile_7.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      